import React, { useRef, useState } from "react"
import { Link } from "gatsby"
import CountUp from "react-countup"
// import Loadable from "@loadable/component"
import Layout from "../components/layout"
import "bootstrap/dist/css/bootstrap.min.css"
// import Image from "../components/image"
import SEO from "../components/seo"
// import ContactForm from "../components/contact"
import { LazyLoadImage } from "react-lazy-load-image-component"
import TechnologyStack from "./TechnologyStack"
import career from "../../career.json"
import ContactForm from "../components/contact"

const testimonials = [
  {
    quote:
      "I would like to personally thank you for your outstanding product. It's the perfect solution for our business. They are pro in web application development services. Highly recommended.",
    author: "Saul Goodman",
  },
  {
    quote:
      "D2i Technology is awesome! I like D2i Technology more and more each day because it makes my life a lot easier. No one is ready to touch our code because of complexity. They took the challenge and did our custom development work smoothly.",
    author: "Gwendolen G.",
  },
  {
    quote:
      "You won't regret it. D2i Technology did exactly what you said it does. I am completely blown away.",
    author: "Cally H.",
  },
  {
    quote:
      "This is simply unbelievable! I would also like to say thank you to all your staff. We hired them to do accessibility testing of our application they not only tested, they helped to fix our accessibility & functional bugs and improve our HTML structure so that custom code for accessibility is not required. I liked the approach. Best accessibility testing company.",
    author: "Matt Brandon",
  },
  {
    quote:
      "Thank you for nice work in doing SEO our application, and most of all hassle free! I don't know what else to say. We have no regrets!",
    author: "John Larson",
  },
  {
    quote:
      "I call them best software development and testing company because they took complete ownership of my project. They just don't follow the requirement document shared, whole team will use the brain to think from users perspective. Inputs shared by their developers & QA helped me to improve UX.",
    author: "Mark",
  },
]

const IndexPage = () => {
  const activeJobs = career.career.filter(
    job => job.status.toLowerCase() === "active"
  )

  const [currentIndex, setCurrentIndex] = useState(0)
  const carouselRef = useRef(null)

  const handleNext = () => {
    setCurrentIndex(prev => (prev === testimonials.length - 1 ? 0 : prev + 1))
  }

  const handlePrevious = () => {
    setCurrentIndex(prev => (prev === 0 ? testimonials.length - 1 : prev - 1))
  }

  const handleKeyDown = e => {
    switch (e.key) {
      case "ArrowLeft":
        handlePrevious()
        break
      case "ArrowRight":
        handleNext()
        break
      case "Home":
        setCurrentIndex(0)
        break
      case "End":
        setCurrentIndex(testimonials.length - 1)
        break
      default:
        break
    }
  }

  return (
    <Layout>
      <SEO
        title="Software Development, DevOps, Accessibility Testing Company in USA"
        description="D2i Technology is a top software company in the USA, offering expert services in software development, DevOps, and accessibility testing. Look no further. Our team of experts can help you develop, deploy, and test software with ease."
      />
      
      <section id="hero" className="d-flex align-items-center">
        <div
          className="container position-relative"
          data-aos-todo="fade-up"
          data-aos-todo-delay="100"
        >
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9 text-center">
              <h1 className="lead">Turning the digital dreams into reality</h1>
              <div className="section-title">
              <p>
                We are a team of talented software developers turning the
                digital dreams of our clients into reality.
              </p>
              </div>
              <button type="button" class="btn btn-primary rounded-pill px-5 py-3" data-toggle="modal" data-target="#contactModal" onClick={() => setTimeout(() => document.querySelector('#contactModal input[name="name"]').focus(), 700)}>
                Get Free Expert Advice {"   "}
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                </svg>
              </button>
            </div>
          </div>

          <div className="row icon-boxes" role="list">
            <div
              className="col-md-4 d-flex align-items-stretch mb-5 mb-lg-0"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="200"
              role="listitem"
            >
              <div className="icon-box">
                <div className="icon">
                  <CountUp end={65} />+
                </div>
                <div className="title">Happy Clients</div>
              </div>
            </div>

            <div
              className="col-md-4 d-flex align-items-stretch mb-5 mb-lg-0"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="300"
              role="listitem"
            >
              <div className="icon-box">
                <div className="icon">
                  <CountUp end={85} />+
                </div>
                <div className="title">Projects</div>
              </div>
            </div>

            <div
              className="col-md-4 d-flex align-items-stretch mb-5 mb-lg-0"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="400"
              role="listitem"
            >
              <div className="icon-box">
                <div className="icon">
                  <CountUp end={16} />+
                </div>
                <div className="title">Years of experience</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="about">
        <div className="container" data-aos-todo="fade-up">
          <div className="section-title">
            <h2>About Us</h2>
            <p>
              At D2i Technology, we turn the digital dreams of our clients into
              reality.{" "}
            </p>
          </div>
          <div className="embed-responsive embed-responsive-16by9 mb-5">
            <iframe
              className="embed-responsive-item"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/0B1q4cI93io"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Welcome to D2i Technology - Best Custom Software Development, Digital Marketing & Testing Services"
              loading="lazy"
            ></iframe>
          </div>

          <div className="row content">
            <div className="col-12 pb-4">
              <p className="lead text-center">
                We work closely with our users throughout the development
                process to ensure that we are aligned with the end goal. We are
                committed to producing exceptional software for each of our
                clients.
              </p>
            </div>
          </div>
          <div className="row content">
            <div className="col-lg-6">
              <h3>Rigorous Testing</h3>
              <p>
                Once we have finished the initial development of our client's
                product, we begin performing meticulous bug checks. These tests
                are included in the price of the development package.{" "}
              </p>
              <p>After testing, we deliver the software to our clients. </p>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>Quality Guaranteed</h3>
              <p>
                The world of technology can be fast-paced and scary. That's why
                our goal is to provide a high-quality product that aligns with
                your company's needs. No matter the budget, we pride ourselves
                on providing professional customer service. We guarantee you
                will be satisfied with our work.
              </p>
            </div>
          </div>
          <div className="row content">
            <div className="col-12 pt-4 text-center">
              <a href="/contact" className="btn-learn-more">
                Learn More <span className="visually-hidden">Our Testing and Quality Assurance</span>
              </a>
              <a href="/schedule-a-call" className="btn-schedule-call">
                Schedule a Call
              </a>
            </div>
          </div>
        </div>
      </section>
      <section id="portfolio" className="portfolio">
        <div className="container" data-aos-todo="fade-up">
          <div className="section-title">
            <h2>Our Products</h2>
          </div>

          <div className="row icon-boxes mt-0">
            <div
              className="col-md-6 col-lg-4 d-flex align-items-stretch mb-4 aos-init aos-animate"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="200"
            >
              <div className="icon-box px-3">
                <div className="mb-4">
                  <LazyLoadImage
                    src="assets/img/ss-pocket-newz.png"
                    className="img-fluid"
                    alt=""
                    loading="lazy"
                    srcSet="assets/img/ss-pocket-newz.png 480w,
                            assets/img/ss-pocket-newz.png 800w,
                            assets/img/ss-pocket-newz.png 1200w"
                    sizes="(max-width: 600px) 480px,
                          (max-width: 1200px) 800px,
                          1200px"
                  />
                </div>
                <h3 className="title">
                  <a
                    href="https://pocketnewz.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Pocket Newz
                  </a>
                </h3>
                <p className="description">
                  Stay updated with top global and local headlines, curated for quick and easy reading.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 d-flex align-items-stretch mb-4 aos-init aos-animate"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="200"
            >
              <div className="icon-box px-3">
                <div className="mb-4">
                  <LazyLoadImage
                    src="assets/img/ss-greeting.png"
                    className="img-fluid"
                    alt=""
                    loading="lazy"
                    srcSet="assets/img/ss-greeting.png 480w,
                            assets/img/ss-greeting.png 800w,
                            assets/img/ss-greeting.png 1200w"
                    sizes="(max-width: 600px) 480px,
                          (max-width: 1200px) 800px,
                          1200px"
                  />
                </div>
                <h3 className="title">
                  <a
                    href="https://greetings.pocketnewz.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Greeting
                  </a>
                </h3>
                <p className="description">
                  Create and share vibrant cards for all occasions, from festivals to personal celebrations.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 d-flex align-items-stretch mb-4 aos-init aos-animate"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="200"
            >
              <div className="icon-box px-3">
                <div className="mb-4">
                  <LazyLoadImage
                    src="assets/img/ss-photography.png"
                    className="img-fluid"
                    alt=""
                    loading="lazy"
                    srcSet="assets/img/ss-photography.png 480w,
                            assets/img/ss-photography.png 800w,
                            assets/img/ss-photography.png 1200w"
                    sizes="(max-width: 600px) 480px,
                          (max-width: 1200px) 800px,
                          1200px"
                  />
                </div>
                <h3 className="title">
                  <a
                    href="https://photo.d2itechnology.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Photography
                  </a>
                </h3>
                <p className="description">
                  Effortlessly share event photos with face recognition technology, ensuring privacy and quick access.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="clients" className="clients">
        <div className="container" data-aos-todo="fade-up">
          <div className="section-title">
            <h2>Our Clients</h2>
          </div>

          <div className="row icon-boxes mt-0">
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch mb-4 aos-init aos-animate"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="200"
            >
              <div className="icon-box px-3">
                <div className="mb-4">
                  <LazyLoadImage
                    src="assets/img/atsign-logo.svg"
                    className="img-fluid"
                    alt=""
                    loading="lazy"
                    srcSet="assets/img/atsign-logo.svg 480w,
                            assets/img/atsign-logo.svg 800w,
                            assets/img/atsign-logo.svg 1200w"
                    sizes="(max-width: 600px) 480px,
                          (max-width: 1200px) 800px,
                          1200px"
                  />
                </div>
                <h3 className="title">
                  <a href="https://atsign.com/" target="_blank" rel="noopener noreferrer">
                    Atsign
                  </a>
                </h3>
                <p className="description">
                  Atsign is flipping the Internet by giving consumers control of
                  their private information through the open-source @platform.
                  This framework enables developers to create applications for
                  People, Entities, and Things, featuring secure exchange of
                  information between known parties.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch mb-4 aos-init aos-animate"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="200"
            >
              <div className="icon-box px-3">
                <div className="mb-4">
                  <LazyLoadImage
                    src="assets/img/emper.png"
                    className="img-fluid"
                    alt=""
                    loading="lazy"
                    srcSet="assets/img/emper.png 480w,
                            assets/img/emper.png 800w,
                            assets/img/emper.png 1200w"
                    sizes="(max-width: 600px) 480px,
                          (max-width: 1200px) 800px,
                          1200px"
                  />
                </div>
                <h3 className="title">
                  <a href="https://emper.com/" target="_blank" rel="noopener noreferrer">
                    Emper
                  </a>
                </h3>
                <p className="description">
                  Emper is a cloud-based self-service interior book formatting
                  and design program, primarily for indie publishers. Specialize
                  is converting a raw manuscript into a professionally formatted
                  and designed book for publication.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch mb-4 aos-init aos-animate"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="200"
            >
              <div className="icon-box px-3">
                <div className="mb-4">
                  <LazyLoadImage
                    src="assets/img/studio22.jpg"
                    className="img-fluid"
                    alt=""
                    loading="lazy"
                    srcSet="assets/img/studio22.jpg 480w,
                            assets/img/studio22.jpg 800w,
                            assets/img/studio22.jpg 1200w"
                    sizes="(max-width: 600px) 480px,
                          (max-width: 1200px) 800px,
                          1200px"
                  />
                </div>
                <h3 className="title">
                  <a href="https://www.studio22.com/" target="_blank" rel="noopener noreferrer">
                    Studio 22 Design
                  </a>
                </h3>
                <p className="description">
                  Creating digital experiences that ignite growth.
                  <br /> Web | Branding | UX/UI.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch mb-4 aos-init aos-animate"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="200"
            >
              <div className="icon-box px-3">
                <div className="mb-4">
                  <LazyLoadImage
                    src="assets/img/knowaround.png"
                    className="img-fluid"
                    alt=""
                    loading="lazy"
                    srcSet="assets/img/knowaround.png 480w,
                            assets/img/knowaround.png 800w,
                            assets/img/knowaround.png 1200w"
                    sizes="(max-width: 600px) 480px,
                          (max-width: 1200px) 800px,
                          1200px"
                  />
                </div>
                <h3 className="title">
                  <a href="http://knowaround.com/" target="_blank" rel="noopener noreferrer">
                    Knowaround
                  </a>
                </h3>
                <p className="description">
                  KnowAround.com allow you to come to one place for access to
                  comprehensive information focused on the assessment of a
                  location in order to help you make informed decisions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="services section-bg">
        <div className="container" data-aos-todo="fade-up">
          <div className="section-title">
            <h2>Services</h2>
            <p>
              We offer a wide range of services covering every aspect of the
              software development life cycle.
            </p>
          </div>

          <div className="row justify-content-center">
            <div
              id="manual-testing"
              className="col-lg-4 col-md-6 d-flex align-items-stretch"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="100"
            >
              <Link to="/manual-testing/" className="d-flex">
                <div className="icon-box iconbox-blue">
                  <div className="icon">
                    <LazyLoadImage
                      src="assets/img/services/functional-testing.png"
                      className="img-fluid"
                      alt=""
                      loading="lazy"
                      width={58}
                      height={58}
                    />
                  </div>
                  <h3>Functional Testing</h3>
                  <p>
                    We play the role of an end-user using most of the
                    application's features to ensure correct behavior. Our
                    manual testing services not only is limited to find
                    functional defects, we go an extra mile to check usability,
                    performance and accessibility defects.
                  </p>
                </div>
              </Link>
            </div>

            <div
              id="automation-testing"
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="200"
            >
              <Link to="/automation-testing/" className="d-flex">
                <div className="icon-box iconbox-orange ">
                  <div className="icon">
                    <LazyLoadImage
                      src="assets/img/services/automation-testing.png"
                      className="img-fluid"
                      alt=""
                      loading="lazy"
                      width={64}
                      height={64}
                    />
                  </div>
                  <h3>Automation Testing</h3>
                  <p>
                  We offer automation testing solutions to support continuous delivery and testing of your applications. Our services ensure enhanced efficiency, superior quality, and faster time-to-market, giving your business a competitive edge.
                  </p>
                </div>
              </Link>
            </div>

            <div
              id="performance-testing"
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="300"
            >
              <Link to="/performance-testing/" className="d-flex">
                <div className="icon-box iconbox-pink">
                  <div className="icon">
                    <LazyLoadImage
                      src="assets/img/services/performance-testing.png"
                      className="img-fluid"
                      alt=""
                      loading="lazy"
                      width={64}
                      height={64}
                    />
                  </div>
                  <h3>Performance Testing</h3>
                  <p>
                  We offer performance testing solutions to assess your application's responsiveness and stability under various workloads. Our services ensure optimal performance, reliability, and a seamless user experience while identifying bottlenecks to enhance scalability.
                  </p>
                </div>
              </Link>
            </div>

            <div
              id="accessibility-testing"
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="100"
            >
              <Link to="/accessibility-testing/" className="d-flex">
                <div className="icon-box iconbox-yellow">
                  <div className="icon">
                    <LazyLoadImage
                      src="assets/img/services/accessibility-testing.png"
                      className="img-fluid"
                      alt=""
                      loading="lazy"
                      width={78}
                      height={78}
                    />
                  </div>
                  <h3>Accessibility Testing</h3>
                  <p>
                    We ensure your application is usable by people with
                    disabilities like hearing, color blindness, old age, and
                    other disadvantaged groups. We provide VPAT report as well.
                  </p>
                </div>
              </Link>
            </div>

            <div
              id="web-development"
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="200"
            >
              <Link to="/website-development/" className="d-flex">
                <div className="icon-box iconbox-red">
                  <div className="icon">
                    <LazyLoadImage
                      src="assets/img/services/web-development.png"
                      className="img-fluid"
                      alt=""
                      loading="lazy"
                      width={64}
                      height={64}
                    />
                  </div>
                  <h3>Web Development</h3>
                  <p>
                  We develop responsive and optimized web applications as per your needs, following recommended web practices, ensuring seamless functionality and user satisfaction across all devices.
                  </p>
                </div>
              </Link>
            </div>

            <div
              id="mobile-development"
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="300"
            >
              <Link to="/mobile-development/" className="d-flex">
                <div className="icon-box iconbox-teal">
                  <div className="icon">
                    <LazyLoadImage
                      src="assets/img/services/mobile-development.png"
                      className="img-fluid"
                      alt=""
                      loading="lazy"
                      width={60}
                      height={60}
                    />
                  </div>
                  <h3>Mobile Development</h3>
                  <p>
                  We deliver high-performance, secure mobile apps tailored to your business needs, ensuring seamless functionality across Android, iOS, and cross-platform environments.
                  </p>
                </div>
              </Link>
            </div>
            <div
              id="accessibility-remediation"
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="300"
            >
              <Link to="/accessibility-remediation/" className="d-flex">
                <div className="icon-box iconbox-teal">
                  <div className="icon">
                    <LazyLoadImage
                      src="assets/img/services/accessibility-remediation.png"
                      className="img-fluid"
                      alt=""
                      loading="lazy"
                      width={64}
                      height={64}
                    />
                  </div>
                  <h3>Accessibility Remediation</h3>
                  <p>
                  Our accessibility remediation service provides an accurate and cost-effective way to achieve Section 508, ADA, or WCAG 2.0, 2.1 compliance, ensuring your digital content is inclusive and accessible to all users.
                  </p>
                </div>
              </Link>
            </div>
            <div
              id="devops"
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="300"
            >
              <Link to="/devops/" className="d-flex">
                <div className="icon-box iconbox-blue">
                  <div className="icon">
                    <LazyLoadImage
                      src="assets/img/services/devops.png"
                      className="img-fluid"
                      alt=""
                      loading="lazy"
                      width={64}
                      height={64}
                    />
                  </div>
                  <h3>DevOps</h3>
                  <p>
                  Shorten your software development life cycle and enable continuous delivery with high-quality software through our DevOps service, ensuring faster releases, enhanced collaboration, and improved operational efficiency.
                  </p>
                </div>
              </Link>
            </div>
            <div
              id="digitalmarketing"
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="300"
            >
              <Link to="/digital-marketing/" className="d-flex">
                <div className="icon-box iconbox-blue">
                  <div className="icon">
                    <LazyLoadImage
                      src="assets/img/services/digital-marketing.png"
                      className="img-fluid"
                      alt=""
                      loading="lazy"
                      width={64}
                      height={64}
                    />
                  </div>
                  <h3>Digital Marketing</h3>
                  <p>
                    As Digital Marketing Company we provide best services which
                    offers comprehensive Emails, Social media posts, Local
                    listing, Blogging and Online marketing services that will
                    help you to increase online presence resulting your business
                    to grow.
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <TechnologyStack />
      <section id="testimonials" className="testimonials">
        <div className="container" data-aos-todo="fade-up">
          <div className="section-title">
            <h2>Testimonials</h2>
          </div>
          <div className="row justify-content-center">
            <div className="carousel slide" ref={carouselRef}>
              <div
                role="region"
                aria-roledescription="carousel"
                aria-label="Customer Testimonials"
                className="position-relative"
              >
                {/* Screen reader announcement */}
                <div
                  className="sr-only"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  Viewing testimonial {currentIndex + 1} of{" "}
                  {testimonials.length}
                </div>

                {/* Navigation Buttons */}
                <div className="d-flex justify-content-between align-items-center">
                  <button
                    onClick={handlePrevious}
                    onKeyDown={handleKeyDown}
                    className="btn btn-primary position-absolute"
                    aria-label="Previous testimonial"
                  >
                    <span className="sr-only">Previous</span>
                    <span aria-hidden="true">&laquo;</span>
                  </button>

                  <button
                    onClick={handleNext}
                    onKeyDown={handleKeyDown}
                    className="btn btn-primary position-absolute"
                    aria-label="Next testimonial"
                  >
                    <span className="sr-only">Next</span>
                    <span aria-hidden="true">&raquo;</span>
                  </button>
                </div>

                {/* Testimonial Cards */}
                <div className="carousel-inner overflow-hidden" aria-live="polite">
                  {testimonials.map((testimonial, index) => (
                    <div
                      key={index}
                      className={`carousel-item ${
                        index === currentIndex ? "active" : ""
                      }`}
                      role="group"
                      aria-roledescription="slide"
                      aria-label={`Testimonial ${index + 1} of ${
                        testimonials.length
                      }`}
                      aria-hidden={index !== currentIndex}
                      style={{
                        transition: "opacity 0.5s ease-in-out",
                        opacity: index === currentIndex ? 1 : 0,
                      //   position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                      }}
                    >
                      <div className="card border-0 shadow-sm testimonial-item">
                        <div className="card-body p-4">
                          <blockquote className="blockquote mb-0">
                            <p className="mb-4">
                              <span className="quote-icon" aria-hidden="true">
                                <i className="bx bxs-quote-alt-left quote-icon-left mr-3"></i>
                              </span>
                              {testimonial.quote}
                              <span className="quote-icon" aria-hidden="true">
                                <i className="bx bxs-quote-alt-right quote-icon-right ml-3"></i>
                              </span>  
                            </p>
                            <footer className="blockquote-footer">
                              <cite title="Author"><strong>{testimonial.author}</strong></cite>
                            </footer>
                          </blockquote>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Indicators */}
                <div className="d-flex justify-content-center mt-4">
                  {testimonials.map((_, index) => (
                    <button
                      key={index}
                      className={`btn btn-sm mx-2 rounded-circle ${
                        index === currentIndex
                          ? "btn-primary"
                          : "btn-secondary"
                      }`}
                      onClick={() => setCurrentIndex(index)}
                      aria-label={`Go to testimonial ${index + 1}`}
                      aria-pressed={index === currentIndex}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="careers" className="careers team section-bg">
        <div className="container aos-init aos-animate" data-aos-todo="fade-up">
          <div className="section-title">
            <h2>Careers</h2>
            <p>We are hiring for Software Engineer for mentioned profiles.</p>
          </div>
          <div className="row justify-content-center">
            {activeJobs.map(job => (
              <div
                key={job.id}
                className="col-lg-5 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay={100 * job.id}
              >
                <Link to={`/${job.url}`} className="member">
                  <div className="member-info">
                    <h3 className="mb-3">{job.title}</h3>
                    <p className="mb-0">{job.summary}</p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className="row content">
            <div className="col-12 pt-4 text-center">
              <a
                href="https://forms.gle/mCYwx55YhT6vA1cJ7"
                className="btn btn-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                APPLY NOW
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="services">
        <div className="container" data-aos-todo="fade-up">
          <div className="section-title">
            <h2>Our Working Model</h2>
          </div>

          <div className="row justify-content-center" role="list">
            <div
              id="hourly-model"
              className="col-lg-4 col-md-6 d-flex align-items-stretch"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="100"
              role="listitem"
            >
              <div className="d-flex">
                <div className="icon-box iconbox-blue">
                  <div className="icon">
                    {/* <i className="bx bxl-dribbble"></i> */}
                    <LazyLoadImage
                      src="assets/img/services/hourly-model.png"
                      className="img-fluid"
                      alt=""
                      loading="lazy"
                      width={58}
                      height={58}
                    />
                  </div>
                  <h3>Hourly Model</h3>
                  <p>
                  An hourly model is established based on the client's requirements and the technology stack for each project.
                  </p>
                </div>
              </div>
            </div>
            <div
              id="monthly-retention-model"
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="200"
              role="listitem"
            >
              <div className="d-flex">
                <div className="icon-box iconbox-orange ">
                  <div className="icon">
                    <LazyLoadImage
                      src="assets/img/services/monthly-retention-model.png"
                      className="img-fluid"
                      alt=""
                      loading="lazy"
                      width={64}
                      height={64}
                    />
                  </div>
                  <h3>Monthly Retention Model</h3>
                  <p>
                  Monthly costs will be charged to the client based on the resource skill set and project requirements.
                  </p>
                </div>
              </div>
            </div>
            <div
              id="fix-bid-model"
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="300"
              role="listitem"
            >
              <div className="d-flex">
                <div className="icon-box iconbox-pink">
                  <div className="icon">
                    <LazyLoadImage
                      src="assets/img/services/fix-bid-model.png"
                      className="img-fluid"
                      alt=""
                      loading="lazy"
                      width={64}
                      height={64}
                    />
                  </div>
                  <h3>Fix Bid Model</h3>
                  <p>
                    For each project we offer a fix cost by analyzing the scope of work and technology stack.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="award" className="award section-bg">
        <div className="container" data-aos-todo="fade-up">
          <div className="section-title">
            <h2>We Won an Award!</h2>
            <p>
              We are thrilled to share that <a href="/"><strong>D2i Technology</strong></a> has been honored with the Best IT Services Award by <a href="https://en.wikipedia.org/wiki/Bhagyashree" target="_blank" rel="noopener noreferrer"><strong>Bhagyashree</strong></a>! This recognition is a testament to the dedication, innovation, and hard work of our incredible team.
            </p>
          </div>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive-item"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/VdVY8G8GKR4"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="D2i Technology Award Announcement"
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </section>

      <section id="unforgettable-moments" className="award section-bg">
        <div className="container" data-aos-todo="fade-up">
          <div className="section-title">
            <h2>Unforgettable Moments with Team D2i</h2>
            <p>
            The grand year-end party celebrated the incredible journey of 2024, highlighting milestones achieved and the laughter shared along the way. It was a day dedicated to honoring the hard work, dedication, and team spirit that made the year truly unforgettable.
            </p>
          </div>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive-item"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/Bru3AphxTw4"
              title="Unforgettable Moments with Team D2i"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </section>
          
      <section className="contact-us-section section-bg">
        <div class="container mt-5">
          <div class="row">
              <div class="col-md-6 contact-info  m-auto">
                <div class="section-title text-left p-0">
                  <h2>Schedule a Consultation with Us</h2>
                </div>
                  <p className="pb-4">
                    Partner with us to experience unparalleled commitment and exceptional results. We prioritize fulfilling your requirements within the estimated budget, utilizing the latest technologies to deliver cutting-edge solutions. Backed by a proven track record, we ensure our clients receive nothing less than the best. Let us bring your vision to life.
                  </p>
                  <div class="contact-method mb-4">
                    <div className="d-inline-flex d-sm-flex d-md-inline-flex d-lg-inline-flex d-xl-inline-flex align-items-center">
                      <div class="icon-circle text-white d-flex justify-content-center align-items-center">
                        <i className="bx bxs-envelope" aria-hidden="true"></i>
                      </div>
                      <div className="ml-3 contact-detail">
                          <strong>Reach Us via Email</strong>
                          <p><a href="mailto:info@d2itechnology.com">info@d2itechnology.com</a></p>
                      </div>
                    </div>
                  </div>
                  <div class="contact-method mb-4">
                    <div className="d-inline-flex d-sm-flex d-md-inline-flex d-lg-inline-flex d-xl-inline-flex align-items-center">
                      <div class="icon-circle text-white d-flex justify-content-center align-items-center">
                        <i className="bx bxs-phone" aria-hidden="true"></i>
                      </div>
                      <div className="ml-3 contact-detail">
                          <strong>Reach Us via Phone</strong>
                          <p><a href="tel:+918265963621">+91 82659 63621</a></p>
                      </div>
                    </div>
                  </div>
                  <div class="contact-method mb-5">
                    <div className="d-inline-flex d-sm-flex d-md-inline-flex d-lg-inline-flex d-xl-inline-flex align-items-center">
                      <div class="icon-circle text-white d-flex justify-content-center align-items-center">
                        <i className="bx bxl-whatsapp" aria-hidden="true"></i>
                      </div>
                      <div className="ml-3">
                          <strong>Reach Us via WhatsApp</strong>
                          <p><a href="https://api.whatsapp.com/send?phone=918265963621&text=Need%20help%20with">+91 82659 63621{" "}</a></p>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="col-md-6 contact">
                  <ContactForm />
              </div>
          </div>
        </div>
      </section>

      <div class="modal fade" id="contactModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="contactModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="modal-title h5" id="contactModalLabel">Get Free Expert Advice</h2>
            </div>
            <div class="modal-body contact">
              <ContactForm />
            </div>
            <button type="button" class="close position-absolute" style={{top: 18, right: 22}} data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
